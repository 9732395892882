export default [
  {
    text: "Выбор номинации",
    link: { name: "StudentYearGrandPrixNomination" },
    key: "nominations",
    disabled: false,
  },
  {
    text: "Данные",
    link: { name: "StudentYearGrandPrixData" },
    key: "profile",
    disabled: false,
  },
  {
    text: "Успеваемость",
    link: { name: "StudentYearGrandPrixPerformance" },
    key: "performance",
    disabled: false,
  },
  {
    text: "Портфолио",
    link: { name: "StudentYearGrandPrixPortfolio" },
    key: "portfolio",
    disabled: false,
  },
];
